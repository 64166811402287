import axios from "axios";

const BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL || "http://192.168.15.24:9010";

export const apiClient = axios.create({
  baseURL: `${BACKEND_URL}/api/v1`
});

export const getLocation = webToken =>
  apiClient.get(`/shared/location/${webToken}`);

export const openLocation = (webToken, locationId) =>
  apiClient.post(`/sharing/open_door/${webToken}`, {
    userLocationId: locationId
  });
