import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import pl from "./pl.json";
import en from "./en.json";

const DEVELOPMENT_MODE = !!process.env.REACT_APP_DEV_MODE || true;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: DEVELOPMENT_MODE,
    defaultNS: "doorOpener",
    fallbackLng: "pl",
    resources: {
      pl,
      en
    },
    react: {
      wait: false,
      bindI18n: "languageChanged loaded",
      bindStore: "added removed",
      nsMode: "default"
    }
  });

export default i18n;
