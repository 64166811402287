import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Slider from "@material-ui/core/Slider";
import Loader from "react-loader-spinner";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { getLocation, openLocation } from "../ApiClient";
import BarrierAnimation from "../assets/barrier.gif";
import DoorAnimation from "../assets/door.gif";
import GateAnimation from "../assets/gate.gif";
import LocationType from "../constants/LocationType";
import ErrorCodes from "../constants/ErrorCodes";

const DoorOpener = () => {
  const { webToken } = useParams();
  const [location, setLocation] = useState();
  const [animation, setAnimation] = useState();
  const [isLoading, setLoading] = useState(false);
  const [isLocationExpired, setLocationExpired] = useState(false);

  useEffect(() => {
    if (webToken) {
      setLoading(true);
      getLocation(webToken)
        .then(response => {
          if (
            ErrorCodes.LOCATION_EXPIRED === get(response, "data.errorCode", "")
          ) {
            setLocationExpired(true);
          } else {
            setLocation(response.data);
          }
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    }
  }, [webToken]);

  useEffect(() => {
    if (location && location.locationType) {
      let animationGif;
      switch (location.locationType) {
        case LocationType.GATE:
          animationGif = GateAnimation;
          break;
        case LocationType.BARRIER:
          animationGif = BarrierAnimation;
          break;
        default:
          animationGif = DoorAnimation;
      }
      setAnimation(animationGif);
    }
  }, [location, setAnimation]);

  const [openHandle, setOpenHandle] = useState(0);
  const [showAnimation, setShowAnimation] = useState(false);

  const displayAnimation = () => {
    setShowAnimation(true);
    setTimeout(() => setShowAnimation(false), 3500);
  };

  let iconType = "/img/icon-door.png";
  if (location && location.locationType) {
    switch (location.locationType) {
      case LocationType.GATE:
        iconType = "/img/icon-gate.png";
        break;
      case LocationType.BARRIER:
        iconType = "/img/icon-barrier.png";
        break;
      default:
        iconType = "/img/icon-door.png";
    }
  }

  const checkAndOpen = (e, value) => {
    if (value === 150 && location) {
      displayAnimation();
      openLocation(webToken, location.id).catch(err => console.error(err));
    }
    setOpenHandle(0);
  };

  const { t } = useTranslation();

  return (
    <div className="door-opener-root">
      <header>
        <img src="/img/logo.png" className="logo" alt="" />
      </header>

      {isLocationExpired && (
        <div className="no-location">
          <img src="/img/no-location.jpg" alt="" />
          <div>{t("locationExpired")}</div>
        </div>
      )}
      {isLoading && (
        <Loader type="Circles" color="#2e94d3" className="loader" />
      )}
      {!isLoading && location && (
        <>
          <div className="box">
            <img src={iconType} className="icon" alt="" />
            <div className="locationText">{location.locationName}</div>
            <div className="hint">{t("slideToUnlock")}</div>
            <div className="door-opener-swipe ">
              <Slider
                min={0}
                max={150}
                value={openHandle}
                onChange={(e, value) => setOpenHandle(value)}
                onChangeCommitted={checkAndOpen}
              />
            </div>
          </div>
          {showAnimation && animation && (
            <div className="curtain">
              <div className="modal">
                <img
                  src={`${animation}?q=${new Date()}`}
                  alt={t("animation")}
                />
              </div>
            </div>
          )}
        </>
      )}
      {!isLocationExpired && !isLoading && !location && (
        <div className="no-location">
          <img src="/img/no-location.jpg" alt="" />
          <div>{t("locationNotFound")}</div>
        </div>
      )}
    </div>
  );
};

export default DoorOpener;
