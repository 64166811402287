import React from "react";
import "../App.css";
import { Route, Switch } from "react-router-dom";
import RouterPaths from "../constants/RouterPaths";
import DoorOpener from "./DoorOpener";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path={RouterPaths.DOOR_OPENER} component={DoorOpener} />
      </Switch>
    </div>
  );
}

export default App;
